.c-common-banner {
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
}

.c-common-action-banner {
  justify-content: space-between;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
}

.c-common-confirmation-banner {
  background-color: $ln-color-green  !important;
  color: white;
}

.c-common-warning-banner {
  background-color: $ln-color-warning  !important;
}

.c-common-error-banner {
  background-color: $ln-color-error  !important;
  color: white;
}

.c-common-info-banner {
  background-color: #00a9cf !important;
}

.c-common-banner-text {
  padding-left: 1rem;
  font-size: 1rem;
}

.c-common-alert-banner {
  background-color: $ln-color-warning  !important;
  padding: 0.5rem 0.5rem;
}

.c-common-stock-button-border {
  border: 2px solid black;
}

.c-common-success-banner {
  background-color: white !important;
  font-weight: bold;
}

.c-common-success-banner.c-common-banner-text {
  margin-left: 1rem;
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.c-common-success-banner.c-common-banner-icon {
  float: right;
  text-align: right !important;
  padding-right: 1rem;
  font-size: 1rem;
  color: $ln-color-green  !important;
}