.c-hotfood-report-batch-item-alt-row {
  background-color: #f6f6f6;
}

.c-hotfood-report-batch-item-desktop {

  // mobile view
  @media screen and (max-width: 720px) {
    display: none;
  }
}

.c-hotfood-report-batch-item-mobile {

  // desktop/tablet view
  @media screen and (min-width: 720px) {
    display: none;
  }

  @media print {
    display: none;
  }
}

.c-hotfood-report-batch-item-out-of-cabinet-column {
  width: 200px;

  // ipad mini portrait mode
  @media screen and (max-width: 768px) {
    width: 130px;
  }
}

.c-hotfood-report-batch-item-forecasted-column {
  width: 120px;
}

.c-hotfood-report-batch-item-cooked-column {
  width: 80px;
}

.c-hotfood-report-batch-item-subsection {
  padding-left: 0.5rem;
}

.c-hotfood-report-calendar-icon {
  height: 1.25rem;
  margin-right: 0.25rem;
  vertical-align: top;
  width: 1.25rem;
}

.c-hotfood-report-counter-item-time-icon {
  width: 0.3rem;
  padding-right: 0.2rem;
}

.c-hotfood-report-temp-check-col-index {

  // mobile view
  @media screen and (max-width: 720px) {
    text-align: left;
  }

  // desktop/tablet view
  @media screen and (min-width: 720px) {
    text-align: right;
  }

  @media print {
    text-align: right;
  }

  width: 40px;
  padding: 0 !important;
}

.c-hotfood-report-temp-check-col-value {
  text-align: left;
  width: 100px;
  padding: 0 0 0 0.5rem !important;
}

.c-hotfood-report-temp-check-card {
  padding-top: 0 !important;
}

// Counter reports
// ---------------
.c-hotfood-report-counter-desktop {

  // mobile view
  @media screen and (max-width: 720px) {
    display: none;
  }
}

.c-hotfood-report-counter-mobile {
  padding-left: 0;

  // desktop/tablet view
  @media screen and (min-width: 720px) {
    display: none;
  }

  @media print {
    display: none;
  }
}

.c-hotfood-report-counter-cabinet-column {
  background-color: white !important;

  // mobile view
  @media screen and (max-width: 720px) {
    display: none;
  }
}

.c-hotfood-report-counter-column-mobile {

  // mobile view
  @media screen and (max-width: 720px) {
    padding-left: 0;
  }
}

// SingleDatePicker (without input box)
// ------------------------------------
.c-hotfood-report-date-picker .ln-c-date-picker {
  height: 0;
}

.c-hotfood-report-date-picker .DateInput_1 {
  display: none;
}

.c-hotfood-report-date-picker .SingleDatePicker_picker {
  top: 1rem !important;
}

.c-hotfood-report-color-orange {
  color: $ln-color-orange;
}

.c-hotfood-report-summary {
  height: 142px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.c-hotfood-report-summary-title {
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.c-hotfood-report-summary-value {
  font-size: 50px;
  text-align: center;
  width: 100%;
}

.c-hotfood-report-tag {
  float: right;
  margin-right: 0.25rem;
}
