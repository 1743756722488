//desktop/tablet
@media screen and (min-width: 720px) {
  .c-pizza-card-container {
    display: none;
  }
  .c-pizza-button-view {
    padding-left: $ln-space-half !important;
  }
  .c-remove-button-padding {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 480px) {
  .c-button-group-gap {
    margin-left: $ln-space-x2;
  }
}

//mobile
@media screen and (max-width: 720px) {
  .c-submit-modal-button {
    margin-left: $ln-space;
    margin-bottom: $ln-space-x2;
  }
  .c-pizza-table-container {
    display: none;
  }
  .c-pizza-margin-top {
    margin-top: $ln-space-x3 !important;
  }
  .c-bottom-pizza-button {
    padding-left: 0 !important;
  }
}
//tablet
@media screen and (min-width: 720px) and (max-width: 960px) {
  .c-production-incrementor {
    display: flex;
  }
}

.c-pizza-card-row:nth-of-type(odd) {
  background-color: $ln-color-light !important;
}

.c-accordion-container {
  padding: $ln-space-x2;
  background-color: $ln-color-white;
}

.c-display-inline {
  display: inline-block;
}

.c-table__cell---pizza-type-sku {
  @include ln-respond-to("max-md") {
    width: 40%;
  }
  width: 50%;
}

.c-table__cell--pizza-sales-forecast {
  @include ln-respond-to("max-md") {
    width: 20%;
  }
  width: 23%;
}

.c-table__cell--pizza-to-make-today {
  @include ln-respond-to("max-md") {
    width: 30%;
  }
  width: 25%;
}

.c-table__cell--pizza-total-made {
  @include ln-respond-to("max-md") {
    width: 30%;
  }
  width: 25%;
}

.c-pizza-border {
  padding: 0.3rem;
  border: 2px solid $ln-color-blue;
}

.c-table__cell--stock-on-hand {
  @include ln-respond-to("max-md") {
    width: 25%;
  }
  width: 30%;
}

.c-stockAvailability {
  padding-left: $ln-space-x8 !important;
}

.c-forecast-edit-total {
  position: relative;
  left: 48px;
  text-align: center;
  display: inline-block;
  width: 62px; // Magic number for text box size
  margin-left: $ln-space;
  margin-right: $ln-space;
}

.c-pizza-modal-button {
  width: 165px;
}

.c-force-white-background {
  background-color: $ln-color-white !important;
}

.c-pizza-margin-bottom-small {
  margin-bottom: 0.5rem;
}

.pizza-production-title {
  @media screen and (max-width: 480px) {
    font-size: 125%;
  }
}

@include ln-respond-to('max-sm') {
  .c-pizza-modal-button-padding {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
