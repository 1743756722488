// mobile view
@media screen and (max-width: 480px) {
  .c-batch-card {
    margin-right: 0 !important;
  }

  .c-batch-card-modal-secondary-button {
    margin-top: $ln-space-x2;
  }

  .c-batch-table-desktop {
    display: none;
  }

  .c-batch-table-in-batch-column {
    padding-right: 0 !important;
    vertical-align: top;
    width: 10px !important;
  }
}

// desktop/tablet view
@media screen and (min-width: 480px) {
  .c-batch-table-mobile {
    display: none;
  }
}

.c-batch-card-no-left-padding {
  padding-left: 0 !important;
}

.c-batch-card-produce-quantity {
  text-align: right;
}

.c-batch-card-title-lable {
  border-color: darkgrey;
  border-style: solid;
  border-width: 2px;
  font-weight: 400;
  color: $ln-color-dark;
  ;
  position: relative;
  float: right;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-right: 1rem;
}

.c-batch-card-title-inline {
  display: inline-block;
}

.c-batch-table-in-batch-edge {
  border-color: green;
  border-left-style: solid;
}

.c-batch-table-incrementor-button {
  padding: 0.5rem 0 0.5rem 0 !important;
}

.c-temperature-modal-input-field {
  height: 50px !important;
}

// Samsung Galaxy S5 desktop view
@media screen and (max-width: 720px) {
  .c-batch-table-mobile-sku {
    padding-right: 0px;
    width: 220px;
  }
}

.c-batch-view-delete-button {
  color: $ln-color-orange;

  &:hover,
  &:focus {
    @extend .c-batch-view-delete-button, :hover !optional;
    @extend .c-batch-view-delete-button, :focus !optional;
    background-color: rgba(229, 80, 0, 0.08) !important;
    color: #e55000 !important;
  }

  @media screen and (min-width: 480px) {
    float: right;
  }
}

.c-availability-button {
  @media screen and (min-width: 480px) {
    float: right;

    div {
      text-align: right;
    }
  }
}

.c-batch-table-responsive-title {
  @media screen and (max-width: 780px) {
    width: 220px !important;
  }
}

.c-no-availability {
  margin-top: 3rem;
}

.c-no-availability-button {
  margin-top: 1rem;

  // desktop view
  @media screen and (min-width: 1200px) {
    margin-left: 19rem;
    // width: 550px;
  }

  // mobile view
  @media screen and (max-width: 1200px) {
    margin-left: 4rem;
    margin-right: 1.5rem !important;
  }
}

.c-no-availability-header-text {
  font-weight: bold;

  // desktop view
  @media screen and (min-width: 480px) {
    font-size: 1.5rem;
  }

  // mobile view
  @media screen and (max-width: 480px) {
    display: inline-block;
    width: 250px;
  }
}

.c-no-availability-icon {
  color: $ln-color-blue;
  height: 3rem !important;
  margin-right: 1rem;
  vertical-align: top;
  width: 3rem !important;

  // desktop view
  @media screen and (min-width: 1200px) {
    margin-left: 15rem;
  }
}

.c-no-availability-text {

  // desktop view
  @media screen and (min-width: 1200px) {
    margin-left: 19rem;
    width: 550px;
  }

  // mobile view
  @media screen and (max-width: 1200px) {
    margin-left: 4rem;
    margin-right: 1.5rem !important;
    padding-top: 0.5rem;
  }
}

.c-closure-reason-radio {
  max-width: 20rem;
}