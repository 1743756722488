.c-form-input-center {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.c-form-input-content {
  text-align: center;
}

.c-form-buttom-center {
  margin-left: 0rem;
}

.c-form-alert-icon-red {
  color: $ln-color-error;
  margin: 0;
  padding: 0;
}

input:is([readonly]):hover {
  border-color: (--ds-mode-default-border-interactive-highlight);
  box-shadow: inset 0 0 0 1px var(--ds-mode-default-border-interactive-highlight);
}

input:is([readonly]):focus {
  background-color: #FFFACD;
  border: 1px solid rgba(25,118,210,0.7);
  border-color: var(--ds-color-palette-brand-1-highlight);
  box-shadow: inset 0 0 0 1px var(--ds-color-palette-brand-1-highlight), 0 0 0 var(--ds-modifier-focus-ring-focus-width) var(--ds-modifier-focus-ring-color);
  cursor: not-allowed;
}
