$ln-webpack: true;

@import '@jsluna/style/scss/main';

// .c-common-title {
//   @extend .ln-c-navigation-wrapper__title !optional;
//   font-size: 22px;
// }

// .c-common-header-spacing {
//   @extend .ln-c-icon-nav__item !optional;
//   padding-right: $ln-space-x3;

//   @include ln-respond-to('max-sm') {
//     padding-top: $ln-space;
//     padding-bottom: $ln-space-x2;
//     padding-left: $ln-space-x3;
//   }
// }

.c-common-header-action {
  background-color: white !important;
  font-weight: normal !important;
}

.c-common-header-action-spacing {
  @extend .c-common-header-action !optional;
  padding-right: $ln-space-x3  !important;
}

.c-common-header-store-label {
  text-decoration-line: none;
}

.c-common-header-store-number {
  text-decoration: underline;
}

.c-common-header-title {
  line-height: var(--ds-modifier-global-bar-height) !important;
}