.c-hotfood-cabinet-table-section {
  width: 180px;
  justify-items: center;
  align-items: center;
  align-self: center;
  align-content: center;
  font-size: 0.9rem;
}

.c-hotfood-cabinet-table-container {
  align-items: center;
}

.c-hotfood-cabinet-table {
  padding: 0.4rem;
  border: 1px solid $ln-color-black;
  background-color: white;
  text-align: center;
  width: 150px;
}

.c-cabinet-status-modal-temperature-input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.c-counter-card-tab-padding {
  padding-left: 1.5rem !important;
}

.c-counter-input-wrapper {
  display: flex;
  justify-content: flex-end;
  text-align: left;
  align-items: center;
  height: 48px !important;
}

.c-counter-input-wrapper-modal {
  justify-content: flex-start !important;
}

.c-counter-input-label {
  flex: 1;
  justify-items: flex-start;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 1.0rem;
}

.c-counter-input-label-modal {
  flex: 0 !important;
}

.c-counter-inputbox-wrapper {
  @media screen and (max-width: 720px) {
    width: 7.0rem !important;
    text-align: right;
  }
}

.c-counter-input-button {
  padding-left: 1.0rem;
  padding-right: 0.5rem;
  @extend .ln-c-button--text;
  @extend .ln-u-font-weight-bold;
  text-decoration-line: underline;
  text-underline-offset: 0.2rem;
  background-color: white;

  &:hover,
  &:focus {
    background-color: white !important;
  }
}

.c-counter-card-button {
  float: right;
  padding-right: 0.5rem;
  font-size: 1.2rem;
  @extend .ln-u-font-weight-bold;
}

.c-counter-alert-title-font {
  font-size: 1.8rem;
  @extend .ln-u-font-weight-bold;
}

// anchor tag will shows mouse pointer so that user can know this works as a link text
a,
a label {
  cursor: pointer;
}

.c-counter-post-submit-card {
  // justify-content: space-between;
  display: inline;
  padding: 5rem 5rem;
  margin-bottom: 1rem;
  text-align: center !important;
  background-color: white !important;
  font-weight: bold;
  font-size: 1.5rem;
}

.c-counter-post-submit-icon {
  margin-right: 1.5rem;
  text-align: right;
  color: $ln-color-green !important;
}

.c-counter-post-submit-text {
  text-align: left;
  margin-right: 10%;
}

.c-counter-card-faulty-button {
  float: right;
  margin-left: $ln-space-x3 !important;

  // mobile view
  @media screen and (max-width: 720px) {
    float: none;
    padding-left: 0 !important;
  }
}

.c-counter-card-fixed-button {
  float: right;

  // mobile/tablet view
  @media screen and (max-width: 940px) {
    display: block;
    float: none;
    margin-top: 1rem;
  }
}