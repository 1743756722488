.c-pizza-tab-height {
    line-height: $ln-space-x5;
}

.c-table__cell--ingredient-type-sku {
    @include ln-respond-to('max-md') {
        width: 17%;
    }
    width: 35%;
}

.c-table__cell--ingredient-quantity {
    @include ln-respond-to('max-md') {
        width: 10%;
    }
    width: 7%;
}

.c-table__cell--ingredient-total-cases {
    @include ln-respond-to('max-md') {
        width: 13%;
    }
    width: 14%;
}

.c-pizza-tab-navigation {
    list-style: none;
    float: left;
}
