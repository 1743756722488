// @jsluna/labs/tooltip.scss is a customisation of the scss for the @jsluna/labs/tooltip component v4.0.0.
// This customisation removes the responsiveness of the tooltip when the page width is within 'sm'.

// stylelint-disable order/properties-alphabetical-order
$ln-tooltip-arrow-size: 10px !default;
$ln-tooltip-background: $ln-color-grey-dark !default;
$ln-tooltip-hover-background: $ln-color-grey-light !default;
$ln-tooltip-width: calc(#{$ln-space-value} * 24) !default;
$ln-tooltip-delay: 0.5s !default;

.ln-c-tooltip {
  display: inline-block;
  position: relative;
}

.ln-c-tooltip__icon {
  @include ln-transition-properties-with-focus((background-color));

  appearance: none;
  background-color: transparent;
  min-height: $ln-size-def;
  min-width: $ln-size-def;
  display: inline-block;
  line-height: $ln-size-def;
  text-align: center;
  border-radius: $ln-border-radius;

  &:hover,
  &:focus {
    background-color: $ln-tooltip-hover-background;
    color: $ln-color-text-active;
  }
}

.ln-c-tooltip__message {
  @include ln-soft('sm');
  @include ln-font($ln-font-body);

  background-color: $ln-tooltip-background;
  color: $ln-color-white;
  border-radius: $ln-border-radius;
  opacity: 0;
  visibility: hidden;

  transition-property: padding-top, padding-bottom, visibility, opacity,
    position, max-height, margin-top;
  transition-duration: $ln-transition-duration;
  transition-timing-function: ease-in-out;

  .is-open & {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: $ln-layer-3;
    margin-top: $ln-space;


    max-height: none;
    margin-top: 0;

  }

  margin-top: 0;
  min-width: $ln-tooltip-width;
  display: inline-block;
  transition-property: visibility,
  opacity;
  transition-delay: $ln-tooltip-delay;
  overflow: visible;

  &.is-open {
    padding: $ln-space-x2;
  }

  .ln-c-tooltip--large & {
    min-width: calc(#{$ln-tooltip-width} * 2);
  }

  .ln-c-tooltip--outlined & {
    background: $ln-color-white;
    color: $ln-color-text;
    border: 2px solid $ln-tooltip-background;
  }
}


.ln-c-tooltip__arrow {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 0;

  &.ln-c-tooltip__arrow--top,
  &.ln-c-tooltip__arrow--top-end,
  &.ln-c-tooltip__arrow--top-start {
    border-width: $ln-tooltip-arrow-size $ln-tooltip-arrow-size 0 $ln-tooltip-arrow-size;
    border-color: $ln-tooltip-background transparent transparent transparent;
    bottom: $ln-tooltip-arrow-size * -1;
    left: 50%;
    right: 50%;
  }

  &.ln-c-tooltip__arrow--bottom,
  &.ln-c-tooltip__arrow--bottom-start,
  &.ln-c-tooltip__arrow--bottom-end {
    border-width: 0 $ln-tooltip-arrow-size $ln-tooltip-arrow-size $ln-tooltip-arrow-size;
    border-color: transparent transparent $ln-tooltip-background transparent;
    top: $ln-tooltip-arrow-size * -1;
    left: 50%;
    right: 50%;
  }

  &.ln-c-tooltip__arrow--right,
  &.ln-c-tooltip__arrow--right-start,
  &.ln-c-tooltip__arrow--right-end {
    border-width: $ln-tooltip-arrow-size $ln-tooltip-arrow-size $ln-tooltip-arrow-size 0;
    border-color: transparent $ln-tooltip-background transparent transparent;
    left: 0;
    top: 50%;
    margin-left: $ln-tooltip-arrow-size * -1;
  }

  &.ln-c-tooltip__arrow--left,
  &.ln-c-tooltip__arrow--left-start,
  &.ln-c-tooltip__arrow--left-end {
    border-width: $ln-tooltip-arrow-size 0 $ln-tooltip-arrow-size $ln-tooltip-arrow-size;
    border-color: transparent transparent transparent $ln-tooltip-background;
    top: 50%;
    right: 0;
    margin-right: $ln-tooltip-arrow-size * -1;
  }
}
