$ln-webpack: true;

@import '@jsluna/style/scss/main.scss';

.c-qpig-border-line {
  border-bottom: 1pt solid black;
}

.c-qpig-circle {
  border: 0.05em solid rgb(165, 164, 164);
  border-radius: 100%;
  height: 1.45em;
  width: 1.45em;
  text-align: center;
}

.c-qpig-font-size-24px {
  font-size: 24px;
}

.c-qpig-no-bullet {
  list-style: none;
}

.c-qpig-table-margin {
  @extend .ln-u-margin;

  @include ln-respond-to('min-sm') {
    margin-left: $ln-space-x4  !important;
    margin-right: $ln-space-x4  !important;
  }
}

.c-qpig-margin {
  @include ln-respond-to('max-sm') {
    margin-left: $ln-space-x4  !important;
    margin-right: $ln-space-x4  !important;
  }
}

.c-qpig-single-image-margin {
  margin-left: $ln-space-x4;

  @include ln-respond-to('max-sm') {
    margin-right: auto;
    margin-left: auto;
  }
}

.c-qpig-image-margin {
  margin-left: 0;

  @include ln-respond-to('max-sm') {
    margin-right: auto;
    margin-left: auto;
  }
}

.c-qpig__image {
  image-orientation: from-image;
}

.c-qpig-image-rotation {
  display: table;
  margin-bottom: $ln-space/2;
}

.c-qpig-image-rotation__inner {
  padding: 50% 0;
  height: 0;
}

.c-qpig-image-rotation__image {
  margin-top: -50%;
}

.c-qpig-pluCode {
  @include ln-respond-to('max-sm') {
    ln-u-margin-top: auto;
  }
}