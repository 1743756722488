.o-common-loading-overlay {
  position: relative;
}

.o-common-loading-overlay__body {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.15); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  padding-top: 25%;
}

.o-common-loading-wrapper {
  height: 30vh;
  margin: 0 auto;
  width: 10rem; // 150px = Magic number
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
