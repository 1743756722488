.c-common-inline {
  display: inline-block;
}

.c-common-hide-in-print{
  @media print {
    display: none;
  }
}

.c-common-only-visible-in-print{
  @media not print {
    display: none;
  }
}

.u-full-width {
  width: 100% !important;
}