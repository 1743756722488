.c-display-batch-card {

  // desktop view
  @media screen and (min-width: 720px) {
    height: 12rem;
  }

  // mobile view
  @media screen and (max-width: 720px) {
    margin-right: 0 !important;
  }
}

.c-display-batch-card-label {
  border-color: darkgrey;
  border-style: solid;
  border-width: 2px;
  color: $ln-color-dark;
  font-weight: 400;
  padding-left: 0;
  text-align: center;
}

.c-display-batch-card-title {
  height: 3.0em;
}

.c-display-no-batch {
  margin-top: 3rem;
}

.c-display-no-batch-icon {
  color: $ln-color-blue;
  height: 3rem !important;
  margin-right: 1rem;
  width: 3rem !important;

  // desktop view
  @media screen and (min-width: 1200px) {
    margin-left: 15rem;
  }
}

.c-display-no-batch-header-text {
  font-weight: bold;

  // desktop view
  @media screen and (min-width: 480px) {
    font-size: 1.5rem;
  }
}

.c-display-no-batch-text {

  // desktop view
  @media screen and (min-width: 1200px) {
    margin-left: 19rem;
    width: 550px;
  }

  // mobile view
  @media screen and (max-width: 1200px) {
    margin-left: 4rem;
    margin-right: 1.5rem !important;
  }
}

.c-display-add-pizza-button {
  @media screen and (min-width: 480px) {
    float: right;
  }
}

//desktop/tablet
@media screen and (min-width: 720px) {
  .c-display-pizza-slice-card-container {
    display: none;
  }
}

//mobile
@media screen and (max-width: 720px) {
  .c-display-pizza-slice-button {
    margin-top: 1rem;
  }

  .c-display-pizza-slice-table-container {
    display: none;
  }

  .display-pizza-slice-cabinet-label {
    padding: 1.25rem 0 0 0.5rem;
  }
}

.display-pizza-slice-card-row:nth-of-type(even) {
  background-color: $ln-color-light !important;
}

.c-pizza-placement-incrementor-heading {
  width: 30%;
}

.c-pizza-placement-incrementor {
  padding-right: 0;
}