.c-common-incrementor {
  display: inline-block;
}

.c-common-incrementor__button {
  display: inline-block;
  padding: 0.5rem;
}

.c-common-incrementor__input-text-box {
  display: inline-block;
  width: 62px; // Magic number for text box size
  margin-left: $ln-space;
  margin-right: $ln-space;
  position: relative;
}

.c-common-fixed-value-width {
  width: 20px; // Fixed value for incrementor value
  margin: auto
}
