.c-common-planner-selection-title {
  // @extend .ln-c-navigation-wrapper__title !optional;
  color: #f06c00;
  font-size: 2rem;
  line-height: 64px;
  float: left;
  background-color: white;

  position: relative;
  white-space: nowrap;
  z-index: 1;
  margin-bottom: 0;

  padding-left: 1.5rem;
  // margin-right: 0 !important;


  width: 100%;

  background-image: repeating-linear-gradient(#fff, #fff 63px, #8e8e8e 0, #8e8e8e 64px);
}

// .ln-o-clearfix2 {
//   //  margin-right: 0 !important;

//   margin-left: 0;
//   clear: both;
//   content: "";
//   display: table;

// }